import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import BeddingBedSpreadsImage from '../../images/photos/products/bedding-bedspreads.png';
import CustomBeddingImage from '../../images/photos/products/custom-bedding.png';
import MirrorsImage from '../../images/photos/products/mirrors.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class BedBathAndKitchen extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="bed-bath-and-kitchen-page">
                    <HelmetEx>
                        <div metadata="title">Bed, Bath, kitchen | bedspreads, mirrors | Elmwood Park, NJ</div>
                        <div metadata="keywords">bed, bat, kitchen,mirrors, bergen county, nj</div>
                        <div metadata="description">
                            We can help you select the perfect bedding and bedspread.
                            No matter what your style, we'll make custom bedding
                            that fits your bed and room décor perfectly.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>Bed, Bath and Kitchen</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="BEDDING AND BEDSPREADS" src={BeddingBedSpreadsImage}>
                                        <p>
                                            We can help you select the perfect bedding
                                            and bedspreads, so you have sweet dreams every night
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="CUSTOM BEDDING" src={CustomBeddingImage}>
                                        <p>
                                            No matter what your style, we'll make custom bedding
                                            that fits your bed and room décor perfectly.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="MIRRORS" src={MirrorsImage}>
                                        <p>
                                            Look to us for a wide range of mirrors that reflect your style and your interior design.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default BedBathAndKitchen;